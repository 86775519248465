import React from 'react'
import styled from 'styled-components'

import { List, ListItem, Paragraph, Strong } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Link } from '../../components/Link'
import { MultiPageFormExample } from '../../examples/react/pages/MultiPageFormExample'
import { NoteItem } from '../../components/NoteItem'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'

const StyledMultiPageForm = styled(MultiPageFormExample)`
  [data-id='statusbar'] {
    position: relative;
    transform: none;
  }
`
// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Monisivuinen lomake">
    <Section title="Periaatteet">
      <List variant="unordered">
        <ListItem>
          Monisivuisella lomakkeella yhdelle sivulle kootaan kaikki samaan
          asiakokonaisuuteen liittyvät tiedot.
        </ListItem>
        <ListItem>
          Monisivuista lomaketta käytetään, jos
          <List noMargin>
            <ListItem>käyttäjältä kysyttäviä tietoja on runsaasti</ListItem>
            <ListItem>
              lomakkeen täyttäminen jakautuu selkeisiin vaiheisiin. Tällöin
              käyttäjän yhteen vaiheeseen täyttämät tiedot voivat vaikuttaa sitä
              seuraavien vaiheiden sisältöön.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Monisivuista lomaketta ei käytetä, jos käyttäjältä kysyttävien
          tietojen määrä mahtuu hyvin yhdellekin sivulle. Tällöin käytetään
          yksisivuista lomaketta tai modaalia.
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Paragraph>
        Monisivuinen lomake koostuu sivunavigaatiosta, sisältöalueesta, tietojen
        tarkastus -sivusta sekä toiminnoista. Tietojen tarkastus -sivu ei ole
        pakollinen, jos tietojen tarkastus on hoidettu muulla tavalla, kuten
        Esikatselu-toiminnolla.
      </Paragraph>
      <NoteItem
        bullet="1"
        title="Sivunavigaatio"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Lomakkeen sivujen navigointiin käytetään{' '}
            <Code>
              <Link page="SideSteps" />
            </Code>
            -komponenttia.
          </ListItem>
          <ListItem>
            Lomakkeen pääotsikko on sivunavigaation yläpuolella.
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="2"
        title="Sisältöalue"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>Lomakkeen sivu muodostuu lomakkeen elementeistä.</ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="3"
        title="Tietojen tarkastus -sivu"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>Lomakkeen viimeinen sivu.</ListItem>
          <ListItem>
            Sivun nimi voi olla tilanteeseen sopiva, esim.{' '}
            <Strong>Tietojen lähetys</Strong> tai{' '}
            <Strong>Suunnitelman hyväksyminen</Strong>.
          </ListItem>
          <ListItem>
            Lomakkeen kaikki tiedot luetellaan listana (
            <Code>
              <Link page="DescriptionList" />
            </Code>
            ). Sivut erotellaan toisistaan väliviivalla (
            <Code>
              <Link page="Divider" />
            </Code>
            ). Jokainen sivun osio rajataan oman kehyksen sisälle (
            <Code>FormLayoutItem</Code>).
          </ListItem>
          <ListItem>
            Jokaisella osiolla on oma <Strong>Muokkaa</Strong>-painike, joka vie
            lomakkeelle kyseisen osion alkuun.
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="4"
        title="Toiminnot-alue"
      >
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            Lomakkeella on <Strong>Edellinen</Strong> ja{' '}
            <Strong>Seuraava</Strong> -painikeet sivuille siirtymiseen.
          </ListItem>
          <ListItem>
            Lomakkeella on myös tarvittaessa <Strong>Keskeytä</Strong> ja{' '}
            <Strong>Jatka myöhemmin</Strong>
            -toiminnot sekä muita toimintoja tarpeen mukaan. Lomakkeen
            päätoiminto (<Strong>Lähetä</Strong>/<Strong>Julkaise</Strong>/
            <Strong>Tallenna</Strong>) on viimeisellä sivulla.
          </ListItem>
          <ListItem>
            Katso myös:{' '}
            <Link to="/patternit/lomakkeen-toiminnot">Lomakkeen toiminnot</Link>{' '}
            ja{' '}
            <Link to="/patternit/monisivuisen-lomakkeen-navigaatio">
              Monisivuisen lomakkeen navigaatio
            </Link>
            .
          </ListItem>
        </List>
      </NoteItem>
      <ZoomedExample
        notes={[{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }]}
      >
        <StyledMultiPageForm step={1} />
      </ZoomedExample>
      <ZoomedExample notes={[{ id: '3' }]}>
        <StyledMultiPageForm step={3} />
      </ZoomedExample>
    </Section>
    <Section title="Tilat ja muunnelmat">
      <SectionHeading>
        Lomakkeen sivut ovat täytettävissä vapaassa järjestyksessä
      </SectionHeading>
      <List variant="unordered">
        <ListItem>
          Jos lomakkeella ei ole toisistaan riippuvia tietoja eri sivujen
          välillä, kaikki sivut voivat olla alusta lähtien saatavilla. Käyttäjä
          voi siis täyttää lomaketta valitsemassaan järjestyksessä ja näin ollen
          hyppiä vapaasti sivujen välillä.
        </ListItem>
      </List>
      <SectionHeading>
        Lomaketta täytetään järjestyksessä sivu kerrallaan
      </SectionHeading>
      <List variant="unordered">
        <ListItem>
          Jos lomakkeella on toisistaan riippuvia tietoja eri sivujen välillä,
          eikä seuraavia sivujen sisältöä voi muodostaa ennen kuin edellisiin
          sivuihin on vastattu, sivut ovat täytettävissa yksi kerrallaan.
          Silloin käyttäjä ei pääse seuraavaan sivuun, ennen kuin kaikki
          edellisen sivun pakolliset tiedot on täytetty. Seuraavat sivut
          esitetään disabloituna sivunavigaatiossa.
        </ListItem>
        <ListItem>
          Kaikkien sivujen ei ole pakko tulla aktiiviseksi ollenkaan koko
          lomakkeen täytön aikana. Jos käyttäjän valinnoista riippuen jotain
          sivua ei käyttäjän tarvitse täyttää ollenkaan, se jää disabloiduksi.
          Tällöin <Strong>Seuraava</Strong>-painike menee seuraavaan saatavilla
          olevaan sivuun.
        </ListItem>
        <ListItem>
          <Strong>Seuraava</Strong>-painike on koko ajan aktiivinen. Jos sivun
          kaikkia pakollisia tietoja ei ole vielä täytetty, näytetään
          virheilmoitus <Strong>Seuraava</Strong>-painiketta painettaessa.
        </ListItem>
      </List>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>Asioinnin lomakkeet</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Useat asioinnin tiedot, jotka käyttäjä lähettää viranomaiselle,
          kysytään monisivuisilla lomakkeilla, esim.{' '}
          <Strong>Työnhakija-asiakkaaksi ilmoittautuminen</Strong>, osa työnhaun
          muutoslomakkeista, <Strong>Työllistymissuunnitelma</Strong>.
        </ListItem>
      </List>
      <SectionHeading>
        Työpaikkailmoitus ja palvelun luonti ja julkaisu
      </SectionHeading>
      <List variant="unordered">
        <ListItem>
          Myös työmarkkinatorin työpaikkailmoitus ja palvelu luodaan
          monisivuisella lomakkeella. Näissä ennen julkaisua tehtävä tietojen
          tarkastaminen tehdään Esikatselu-toiminnossa.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
